<template>
	<div class="app-body">
		<div class="a-flex-rsbc a-ptb-6">
			<span class="a-fs-16 a-fw-b">定价管理</span>
			<div class="a-flex-rcc">
				<le-export-btn :pageParam="pageParam"></le-export-btn>
				<el-button icon="el-icon-plus" type="primary" class="s-btn-add a-ml-24" @click="handlerAddPrice" v-if="$_has(10)">定价</el-button>
			</div>

		</div>


		<el-card class="el-main">
			<le-search-form @reset="handlerRest" @search="handlerSearch" :canPackUp="false" id="leSearch">
				<le-input-icon-search label="定价名称" v-model="pageParam.params.searchKey" />
				<le-input-icon-search label="定价ID" v-model="pageParam.params.id" />
				<le-select-local-search label="定价类型" v-model="pageParam.params.type"
					:options="typeOptions" />
			</le-search-form>

			<le-pagview ref="pricelist" @setData="setTableData" :pageParam="pageParam" :total.sync="total" :isFixed="true" :tableRef="$refs.pricelistTable">
				<el-table stripe ref="pricelistTable" :data="tableData" :highlight-current-row="true" v-sticky="{ top: 0, parent:'.el-card__body' }">
					<el-table-column prop="id" label="定价ID" width="120" >
					</el-table-column>
                    <el-table-column prop="name" label="定价名称" width="200" >
					</el-table-column>
					<el-table-column prop="description" label="定价描述" width="250" >
					</el-table-column>
					<el-table-column prop="companyName" label="定价商户"  width="200" >
					</el-table-column>
					<el-table-column label="定价类型" min-width="120">
						<template slot-scope="{ row }">
							<span>{{ row.type | initDic(typeOptions) }}</span>
						</template>
					</el-table-column>
					<el-table-column label="起步时长">
						<template slot-scope="{ row }">
							<span v-if="row.type != '2' && row.type != '5' && row.type != '7' && row.type != '9' && row.type != '10' && row.type != '11'">{{ row.baseMinutes % 60 == 0 ? (row.baseMinutes / 60 + '小时') : (row.baseMinutes + '分钟') }}</span>
							<!-- <span v-else-if="row.type == '5'">{{ row.lowChoice % 60 == 0 ? (row.lowChoice / 60 + '小时') : (row.lowChoice + '分钟') }}</span> -->
                            <span v-else>-</span>
						</template>
					</el-table-column>
					<el-table-column label="起步费用/电费" min-width="140">
						<template slot-scope="{ row }">
							<span v-if="row.type != '2' && row.type != '5' && row.type != '7' && row.type != '9' && row.type != '10' && row.type != '11'">{{ util.numFormat(row.baseAmount) }}元</span>
							<span v-else>{{ util.numFormatExcept10To5(row.baseAmount) }}元/kwh</span>
						</template>
					</el-table-column>
					
					<el-table-column label="加时费用" min-width="120">
						<template slot-scope="{ row }">
							<span v-if="row.type != '2' && row.type != '5' && row.type != '7' && row.type != '9' && row.type != '10' && row.type != '11'">
                                {{ util.numFormat(row.stepAmount) }}元/
                                {{ row.stepMinutes % 60 == 0 ? (row.stepMinutes / 60 + '小时') : (row.stepMinutes +'分钟')}}
                            </span>
							<span v-else>-</span>
						</template>
					</el-table-column>

                    <el-table-column label="服务费" min-width="150">
						<template slot-scope="{ row }">
							<span v-if="row.type == '2' || row.type == '7' || row.type == '12'">
                                {{ util.numFormat(row.chargeBaseFee) }}元/
                                {{ row.chargeBaseTime % 60 == 0 ? (row.chargeBaseTime / 60 + '小时') : (row.chargeBaseTime +'分钟')}}
                            </span>
                            <span v-else-if="row.type == '5' || row.type == '10' || row.type == '11'">
                                {{ util.numFormatExcept10To5(row.chargeBaseFee) }}元/kwh
                            </span>
							<span v-else>-</span>
						</template>
					</el-table-column>

                    <el-table-column label="占位费" min-width="150">
						<template slot-scope="{ row }">
							<span v-if="row.serviceBaseFee && row.serviceBaseFee !== 0 && row.serviceBaseFee !== '0'">
                                {{ util.numFormat(row.serviceBaseFee) }}元/
                                {{ row.serviceBaseTime % 60 == 0 ? (row.serviceBaseTime / 60 + '小时') : (row.serviceBaseTime +'分钟')}}
                            </span>
                            <span v-else>-</span>
						</template>
					</el-table-column>

                    <el-table-column label="管理费" min-width="150">
						<template slot-scope="{ row }">
							<span v-if="row.needManagermentFee">
                                {{ util.numFormat(row.managermentFee) }}元/
                                {{ row.managermentMinutes % 60 == 0 ? (row.managermentMinutes / 60 + '小时') : (row.managermentMinutes +'分钟')}}
                            </span>
							<span v-else>-</span>
						</template>
					</el-table-column>

					<el-table-column label="免费时长">
						<template slot-scope="{ row }">
							<span>{{ row.hesitationMinutes?row.hesitationMinutes:'0' }}分钟</span>
						</template>
					</el-table-column>
					<el-table-column label="最长时长">
						<template slot-scope="{ row }">
							<span v-if="row.type == '0' || row.type == '1'">{{ row.maxMinutes?row.maxMinutes:'-' }}分钟</span>
                            <span v-else>-</span>
						</template>
					</el-table-column>

					<el-table-column label="使用中站点数" min-width="110">
						<template slot-scope="{ row }">
							<span>{{ row.useStationSize }}个</span>
						</template>
					</el-table-column>
                    <el-table-column label="备注" min-width="320">
						<template slot-scope="{ row }">
                            <div class="a-flex-cfsfs" style="align-items: stretch;">
                                <div class="a-flex-rfsc" v-for="(item,index) in row.ruleList" :key="index">
                                    <span>{{ item.startTime }}</span>
                                    <span>-</span>
                                    <span>{{ item.endTime }}</span>
                                    <span>时，{{ util.numFormat(item.serviceFee) }}元/</span>
                                    <span>{{ item.serviceMinutes }}分钟，</span>
                                    <span>{{ item.feeRuleType | initDic(feeRuleTypeDic) }}</span>
                                </div>
                            </div>
                            
						</template>
					</el-table-column>
					
					<el-table-column label="操作" fixed="right" width="80">
						<template slot-scope="{ row }">
							<div class="a-flex-rcc">
								<div @click="handlerEditPrice(row)" class="a-cursor-p" v-if="$_has(10)">
									<el-tooltip class="item" effect="dark" content="编辑定价" placement="top" >
										<img src="../../assets/icon/option-edit.png" class="a-wh-16"/>
									</el-tooltip>
								</div>
								<div @click="handlerDelPrice(row.id)" class="a-cursor-p a-ml-16">
									<el-tooltip class="item" effect="dark" content="删除定价" placement="top" >
										<img src="../../assets/icon/option-refuse.png" class="a-wh-16"/>
									</el-tooltip>
								</div>
							</div>
						</template>
					</el-table-column>
				</el-table>
				<template slot="footerLabel">
					<div class="a-flex-rcfe ">
						<span class="a-c-666 a-fs-12">筛选出</span>
						<span class="a-fs-16 a-fw-b a-plr-10">{{total}}</span>
						<span class="a-c-666 a-fs-12">个定价</span>
					</div>
					
				</template>
			</le-pagview>
		</el-card>
	</div>
</template>

<script>
	import util from "../../utils/util.js";
	export default {
		mounted() {},
		data() {
			return {
				util: util,
				typeOptions: [],
				tableData: [],
				pageParam: {
					url: this.$Config.apiUrl.getPlanList,
					method: "post",
					params: {
						searchKey: "",
						type: "",
                        id: ''
					},
					freshCtrl: 1,
				},
				total:0,
                feeRuleTypeDic: [],
			};
		},
		mounted() {
            this.$getDic('feeRuleType').then(res=>{ this.feeRuleTypeDic = res; })
            this.$getDic('chargingPlanType','select').then(res=>{ this.typeOptions = res; })
        },
		activated () {
			this.pageParam.freshCtrl++;
		},
		methods: {

			//获取车辆列表
			setTableData(data) {
				this.tableData = data;
			},

			handlerRest() {
				this.pageParam.params = {
					searchKey: "",
					type: "",
                    id: ''
				}
				this.handlerSearch()
			},

			handlerSearch() {
				this.$refs['pricelist'].pageNum = 1
				this.pageParam.freshCtrl++
			},

			handlerAddPrice() {
				this.$router.push({
					path: '/price/price-edit'
				})
			},
			
			handlerEditPrice(datas){
				this.$router.push({
					path: '/price/price-edit',
					query:{
						priceId:parseInt(datas.id),
                        type: datas.type
					}
				})
			},
			
			handlerDelPrice(priceId){
				let that = this;
				// 解绑
				this.$confirm('是否确认删除该定价?', '温馨提示', {
				    confirmButtonText: '确定',
				    cancelButtonText: '取消',
				    type: 'warning'
				}).then(_=>{
				    that.$Axios._get({
				        url: that.$Config.apiUrl.delPlan,
				        params: {
				            chargingPlanId: priceId
				        }
				    }).then(({data}) => {
				    	that.$message.success("删除定价成功")
						that.handlerSearch();
				    })
				    
				}).catch(_=>{})
			}
		}
	}
</script>

<style scoped lang="scss">
	/deep/ .el-table__body-wrapper::-webkit-scrollbar {
		/*width: 0;宽度为0隐藏*/
		width: 0 !important;
		height: 0 !important;
	}
	/deep/ .el-table__fixed, /deep/ .el-table__fixed-right{
		height: 100% !important;
	}
</style>
